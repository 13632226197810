import { useState, useEffect } from "react"
import axios from "axios";
import ScenarioModal from "@modals/ScenarioModal";

const PartnershipView = () => {
  const [faq, setFAQ] = useState([]);
  const [idx, setIdx] = useState(0);
  const [isOpen, setOpen] = useState(false); // 시나리오 모달

  useEffect(() => {
    loadFAQ();
  }, []);

  const loadFAQ = async () => {
    const { data } = await axios.get('/constants/faq/', {
      params: {
        category: 'MEMBER',
      }
    });
    console.log(data);
    setFAQ(data);
  };

  return (
    <>
      <div className="invt-code">
        <div className="inner">
          <div className="txt-box">
            <strong className="">신규 서비스 구축이나 개편을 위해<br />아웃소싱이 필요한 기업을 위한 파트너십</strong>
            <p></p>
            {/* <p>토이프로젝트가 아닌 고객사의 실무 프로젝트를 제안 받고, 전문적인 커리어를 쌓을 수 있어요</p> */}
            <div className="in-btn">
              <a href="#" target="_blank">서비스 소개서</a>
              {/* <a href="/join">초대코드 입력하고 가입하기</a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="join-profile">
        <div className="inner">
          <div className="txt-box">
            <span>01</span>
            <p>어떤 실무자들과 일하게 되나요?</p>
            <ul>
              {/* <li>세컨팀 멤버는 기존 멤버의 초대를 통해서만 가입이 가능합니다.</li> */}
              <li>많은 고객사들이 항상 ‘완벽한 능력자'를 찾지만, 완벽함 이란 존재하지 않습니다. 함께 일하는 실무자들의 장단점과 적재적소의 투입하여 성과를 만드는 아웃소싱 전략이 중요합니다.</li>
              <li>세컨팀은 15년차 PM이 다수의 프로젝트에서 <strong>직접 협업해본 전문 인력들</strong>과 추천제 시스템으로 구성된 인력들로 프로젝트에 맞는 <strong>인력매칭을 직접 진행</strong>하고 있습니다. </li>
            </ul>
          </div>
        </div>
        <div className="inner">
          <div className="txt-box">
            <span>02</span>
            <p>믿고 맡길 수 있나요?</p>
            <ul>
              <li>맡기기만 해도 잘 되는 업무가 있고, 아닌 것도 있습니다. 대부분의 아웃소싱 문제는 협업기간에서 발생되는 상호작용의 부재나 의사결정부재에서 비롯됩니다.</li>
              <li>세컨팀은 <strong>2주 단위(스프린트)로 일하는 계약체계를 가지고 있고, 팀의 협업 및 소통 방식</strong>을 직접 관리합니다. 이 과정을 통해 상호간의 리스크를 빠르게 발견하고 대응 시스템을 구축하는 것을 목표로 진행됩니다.</li>
              {/* <li>주중 시간이 아니더라도 저녁, 주말 등 가능한 시간으로 기입이 가능합니다.</li> */}
            </ul>
          </div>
        </div>
        <div className="inner">
          <div className="txt-box">
            <span>03</span>
            <p>IT를 잘 모르신다면,<br />맡기지 말고 직접 경험해야 합니다.</p>
            <ul>
              <li>IT에 대한 전문인력이 부재한 팀일수록, 어떤 전문가가 필요하고 얼마의 예산이 적합한지 판단이 어렵습니다. 그렇기 때문에 주변의 프리랜서나 도움을 받으면서 일이 더 복잡하고 힘들어집니다.</li>
              <li>세컨팀은 <strong>전담 매니저와 파트너사의 매니저가</strong> 한 팀으로 활동합니다. 세컨팀의 목표는 내부 조직의 IT 지식과 이해도를 높이는 것에 좀더 초점을 맞춰 아웃소싱 프로젝트를 진행하고 있습니다. <br/>( 희망시, <strong>고객사를 위한 IT 매니징 교육</strong>을 함께 진행합니다 ) </li>
              <li>회사와 협의하여,  주당 업무 시간과 비용, 협업 기간을 확정합니다.</li>
            </ul>
          </div>
        </div>
        <div className="inner">
          <div className="txt-box">
            <span>04</span>
            <p>IT를 잘 아신다면, <br />애자일한 팀이 필요합니다.</p>
            <ul>
              <li>일반적인 외주사나 프리랜서의 경우 애자일한 협업 방식이나 현업 최신 기술에 대한 이해도가 부족하여, 대부분 포기하거나 시도조차 하지 않는 경우가 많습니다.</li>
              <li>세컨팀은 프리랜서 보다 오히려 현업의 전문인력들이 많기에, 실제 회사의 방식에 익숙하고 다양한 기술과 실무 이해도가 있는 분들이 많아 오히려 더 좋은 협업 파트너가 될 수 있습니다. </li>
            </ul>
          </div>
        </div>
        <div className="inner">
          <div className="txt-box">
            <span>05</span>
            <p>필요한 인원과 목적에 따라 <br />세컨팀을 활용합니다.</p>
            <ul>
              <li>특정 언어나 기술, 진행중인 프로젝트의 투입 등 다양한 조건에 맞는 프리랜서나 외주업체를 찾는 것부터 어려움이 많습니다.</li>
              <li>세컨팀은 회사의 프로젝트 상황에 맞춰 1인 부터 팀 단위로 해당 스킬을 기반으로 함께 유연한 세팅이 가능합니다.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pricing">
        <h2>Pricing</h2>
        <table>
          <caption>Pricing</caption>
          <thead>
            <tr>
              <th></th>
              <th scope="col">프로젝트 파트너십</th>
              <th scope="col" className="disb">엔터프라이즈 파트너십</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">고정 비용</th>
              <td>
                {/* <span style={{ textDecoration: 'line-through', fontWeight: 'normal' }}>
                  정산 비용의 <br />5%
                </span> */}
                <div>
                  없음
                </div>
              </td>
              <td className="disb">월 400만원 ~</td>
            </tr>
            <tr>
              <th scope="row">프로젝트 비용</th>
              <td>
                <span >
                  투입인력 실 인건비
                </span>
                <div style={{ marginTop: '5px' }}>
                  (수수료 10~20%)
                </div>
              </td>
              <td className="disb">월 400만원 ~</td>
            </tr>
            <tr>
              <th scope="row">프로젝트 매니징 비용</th>
              <td>
                <div>
                  150만원 ~  
                </div>
              </td>
              <td className="disb">무료</td>
            </tr>
            <tr>
              <th scope="row">채용 연계시</th>
              <td>
                <div>
                  연봉의 5%
                </div>
              </td>
              <td className="disb">100만원</td>
            </tr>
          </tbody>
        </table>
        <p className="vat">모든 가격은 VAT 별도입니다.</p>
        <div className="in-btn">
          <a style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>시나리오 예시</a>
        </div>
      </div>
      <div className="pjt-comp">
          <div className="inner">
            <p><span>고객 사례</span></p>
            <ul>
              <a href="https://medium.com/@secondteam"
              target="_blank">
                <li>
                  <span className="title">로컬스티치</span>
                  <span className="tag">#엔터프라이즈 파트너십</span>
                </li>
              </a>
              <a href="https://medium.com/@secondteam/%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EC%BC%80%EC%9D%B4%EC%8A%A4-%EC%97%B4%EB%A6%B0%EC%98%B7%EC%9E%A5-%ED%9A%A8%EC%9C%A8%EC%A0%81%EC%9D%B8-%EB%A6%AC%EB%89%B4%EC%96%BC-w-%EB%A0%88%EA%B1%B0%EC%8B%9C-%EC%8B%9C%EC%8A%A4%ED%85%9C-870e6ec7a6f2"
              target="_blank">
                  <li>
                    <span className="title">열린옷장</span>
                    <span className="tag">#엔터프라이즈 파트너십</span>
                  </li>
              </a>
              <a href="https://medium.com/@secondteam/%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EC%BC%80%EC%9[…]F%84-%ED%95%B4%EA%B2%B0%ED%96%88%EC%96%B4%EC%9A%94-4ab5e6abdedd"
              target="_blank">
                  <li>
                    <span className="title">고알레</span>
                    <span className="tag">#프로젝트 파트너십</span>
                  </li>
              </a>
              <a href="https://medium.com/@secondteam"
              target="_blank">
                  <li>
                    <span className="title">테오라</span>
                    <span className="tag">#프로젝트 파트너십</span>
                  </li>
              </a>
              <a href="https://medium.com/@secondteam"
              target="_blank">
                  <li>
                    <span className="title">하이커텍트</span>
                    <span className="tag">#엔터프라이즈 파트너십</span>
                  </li>
              </a>
              <a href="https://medium.com/@secondteam"
              target="_blank">
                  <li>
                    <span className="title">디핑</span>
                    <span className="tag">#엔터프라이즈 파트너십</span>
                  </li>
              </a>
              <a href="https://medium.com/@secondteam"
              target="_blank">
                  <li>
                    <span className="title">에스티씨랩</span>
                    <span className="tag">#엔터프라이즈 파트너십</span>
                  </li>
              </a>
              <a href="https://medium.com/@secondteam"
              target="_blank">
                  <li>
                    <span className="title">브라이언 임팩트</span>
                    <span className="tag">#프로젝트 파트너십</span>
                  </li>
              </a>
              <a href="https://medium.com/@secondteam"
              target="_blank">
                  <li>
                    <span className="title">스페이스클라우드</span>
                    <span className="tag">#프로젝트 파트너십</span>
                  </li>
                </a>
            </ul>
        </div>
      </div>
      {/* <div className="invt-app">
        <div className="inner">
          <strong>세컨팀 멤버는 초대를 통해서만 가입할 수 있습니다.</strong>
          <p>세컨팀 멤버가 되고싶으신가요? 신청서를 작성하시면 시소가 검토후 사전 대기열에 따라 세컨팀 멤버로 등록해드립니다.</p>
          <div className="in-btn">
            <a href="https://bit.ly/3yqQISG" target="_blank">사전모집 지원하기</a>
            <a href="https://seesorufree.notion.site/7a78b679cc284478a2282b4ab9d98c87" target="_blank">초대장 신청하기</a>
          </div>
        </div>
      </div> */}
      <div className="main-faq">
        <h2>자주 물어보는 질문</h2>
        <div className="inner">
          <ul>
            {
              faq.map(({ question, answer }, index) => (
                <li className={idx === index ? 'selected' : ''} onClick={() => setIdx(index)}>
                  <em>{question}</em>
                  <div className="ans">
                    <p>{answer}</p>
                  </div>
                </li>
              ))
            }
          </ul>
          {/* <div className="in-btn">
            <a href="#">자세히 알아보기</a>
          </div> */}
        </div>
      </div>
      <div className="steam-join">
        <div className="inner">
          <strong>충분한 실력의 인재를 발견하면<br />함께 일할 기회를 먼저 만드세요.</strong>
          
          {/* <p>내 전문성과 무관한 단순 부업이 아닌 내가 잘하고, 하고싶은 일을 하며 성장에 도움이 되는 일에 도전해보세요.</p>
          <p>세컨팀은 당신의 가능성을 넓혀드립니다.</p> */}
          <div className="in-btn">
            <a href="#" target="_blank">세컨팀 의뢰하기</a>
          </div>
        </div>
      </div>

      <ScenarioModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </>
  )
};

const EnterpriseView = () => {
  const [faq, setFAQ] = useState([]);
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    loadFAQ();
  }, []);

  const loadFAQ = async () => {
    const { data } = await axios.get('/constants/faq/', {
      params: {
        category: 'COMPANY',
      }
    });
    console.log(data);
    setFAQ(data);
  };

  return (
    <>
      <div className="invt-code comp">
        <div className="inner">
          <div className="txt-box">
            <strong>제품팀을 별도 채용하지 않고, <br />세컨팀 자원으로 운영하고자 하는 기업</strong>
            <p></p>
            <div className="in-btn">
              <a href="/join">서비스 소개서</a>
            </div>
          </div>
        </div>
      </div>
      <div className="join-profile comp">
        <div className="inner">
          <div className="txt-box">
            <span>01</span>
            <p>채용을 할 수 있어도<br />할 수 없는 상황이 있는 팀을 위해</p>
            <ul>
              <li>회사와 사업은 성장하고 있지만, 안정적인 개발 및 제품팀 운영을 못하는 팀들이 많습니다. 아는 수십년차 개발자나 슈퍼맨같은 1명이 있더라도 결과물이나 일이 원할하지 못하는 경우죠.</li>
              <li>세컨팀은 무리한 채용 보다는, 제품팀을 활용한 </li>
            </ul>
            {/* <div className="in-btn">
              <a href="#">서비스 소개서</a>
            </div> */}
          </div>
        </div>
        <div className="inner">
          <div className="txt-box">
            <span>02</span>
            <p>실제 회사에 필요한 업무를<br />아웃소싱으로 진행합니다.</p>
            <ul>
              <li>신규 서비스 구축, 홈페이지 리뉴얼 등 실제 이직여부와 무관하게 실제 회사에 도움이 필요한 업무를 수행합니다.</li>
              <li>해당 아웃소싱 프로젝트를 진행하면서 실비는 발생됩니다.</li>
            </ul>
          </div>
        </div>
        <div className="inner">
          <div className="txt-box">
            <span>03</span>
            <p>상호간 일해본 뒤,<br/> 협상을 진행합니다.</p>
            <ul>
              <li>기업은 해당 인재의 퍼포먼스, 커뮤니케이션 스타일을 직접 확인해보고 세컨팀 매니저를 통해 협상 오퍼를 제공합니다.</li>
              <li>실무자 역시, 회사의 상황이나 업무 방식을 확인하고 다음번 이직을 위한 협상을 고려 판단합니다. </li>
              <li>멤버와 업무 방식에 대해서 확정합니다.</li>
            </ul>
          </div>
        </div>
        <div className="inner">
          <div className="txt-box">
            <span>04</span>
            <p>당장의 이직이 아니더라도<br/> 확실한 능력자를 채용하세요.</p>
            <ul>
              <li>시간에 쫓겨 지원자를 결정하는 것은 복불복에 가깝습니다. 다소 시간이 걸리더라도 확실한 경험을 기반으로 뽑아보세요.</li>
              <li>이직 협상을 하더라도 최대 6개월의 기간이 소요될수는 있지만 검증된 실력의 지원자를 확정할수 있습니다.</li>
            </ul>
          </div>
        </div>
        <div className="inner">
          <div className="txt-box">
            <span>05</span>
            <p>기업에게도, 이직러에게도<br/> 새로운 대안이 됩니다.</p>
            <ul>
              <li>실제 프로젝트 기간동안 이직을 고려하는 멤버들이 많고, 고객사 사례에서도 생각보다 많은 채용연계가 이뤄지고 있습니다. </li>
              <li>핵심인재가 중요해질수록, 기업도 구직자도 함께 일하고 Fit을 살펴보고 결정하는 방법을 추천드립니다.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pricing">
        <h2>Pricing</h2>
        <table>
          <caption>Pricing</caption>
          <thead>
            <tr>
              <th></th>
              <th scope="col" className="disb">프로젝트 파트너십</th>
              <th scope="col">엔터프라이즈 파트너십</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">고정 비용</th>
              <td className="disb">
                {/* <span style={{ textDecoration: 'line-through', fontWeight: 'normal' }}>
                  정산 비용의 <br />5%
                </span> */}
                <div>
                  없음
                </div>
              </td>
              <td>월 400만원 ~</td>
            </tr>
            <tr>
              <th scope="row">프로젝트 비용</th>
              <td className="disb">
                <span >
                  투입인력 실 인건비
                </span>
                <div style={{ marginTop: '5px' }}>
                  (수수료 10~20%)
                </div>
              </td>
              <td>월 400만원 ~</td>
            </tr>
            <tr>
              <th scope="row">프로젝트 매니징 비용</th>
              <td className="disb">
                <div>
                  150만원 ~  
                </div>
              </td>
              <td>무료</td>
            </tr>
            <tr>
              <th scope="row">채용 연계시</th>
              <td className="disb">
                <div>
                  연봉의 5%
                </div>
              </td>
              <td>100만원</td>
            </tr>
          </tbody>
        </table>
        <p className="vat">모든 가격은 VAT 별도입니다.</p>
        <div className="in-btn">
          <a>시나리오 예시</a>
        </div>
      </div>
      <div className="pjt-comp">
          <div className="inner">
              <p><span>고객 사례</span></p>
              <ul>
                <a href="https://medium.com/@secondteam"
                  target="_blank">
                  <li>
                    <span className="title">로컬스티치</span>
                    <span className="tag">#엔터프라이즈 파트너십</span>
                  </li>
                </a>
                <a href="https://medium.com/@secondteam/%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EC%BC%80%EC%9D%B4%EC%8A%A4-%EC%97%B4%EB%A6%B0%EC%98%B7%EC%9E%A5-%ED%9A%A8%EC%9C%A8%EC%A0%81%EC%9D%B8-%EB%A6%AC%EB%89%B4%EC%96%BC-w-%EB%A0%88%EA%B1%B0%EC%8B%9C-%EC%8B%9C%EC%8A%A4%ED%85%9C-870e6ec7a6f2"
                target="_blank">
                    <li>
                      <span className="title">열린옷장</span>
                      <span className="tag">#엔터프라이즈 파트너십</span>
                    </li>
                </a>
                <a href="https://medium.com/@secondteam/%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8-%EC%BC%80%EC%9[…]F%84-%ED%95%B4%EA%B2%B0%ED%96%88%EC%96%B4%EC%9A%94-4ab5e6abdedd"
                target="_blank">
                    <li>
                      <span className="title">고알레</span>
                      <span className="tag">#프로젝트 파트너십</span>
                    </li>
                </a>
                <a href="https://medium.com/@secondteam"
                target="_blank">
                    <li>
                      <span className="title">테오라</span>
                      <span className="tag">#프로젝트 파트너십</span>
                    </li>
                </a>
                <a href="https://medium.com/@secondteam"
                target="_blank">
                    <li>
                      <span className="title">하이커텍트</span>
                      <span className="tag">#엔터프라이즈 파트너십</span>
                    </li>
                </a>
                <a href="https://medium.com/@secondteam"
                target="_blank">
                    <li>
                      <span className="title">디핑</span>
                      <span className="tag">#엔터프라이즈 파트너십</span>
                    </li>
                </a>
                <a href="https://medium.com/@secondteam"
                target="_blank">
                    <li>
                      <span className="title">에스티씨랩</span>
                      <span className="tag">#엔터프라이즈 파트너십</span>
                    </li>
                </a>
                <a href="https://medium.com/@secondteam"
                target="_blank">
                    <li>
                      <span className="title">브라이언 임팩트</span>
                      <span className="tag">#프로젝트 파트너십</span>
                    </li>
                </a>
                <a href="https://medium.com/@secondteam"
                target="_blank">
                    <li>
                      <span className="title">스페이스클라우드</span>
                      <span className="tag">#프로젝트 파트너십</span>
                    </li>
                  </a>
              </ul>
          </div>
      </div>
      {/* <div className="invt-app">
        <div className="inner">
          <strong>세컨팀 멤버는 초대를 통해서만 가입할 수 있습니다.</strong>
          <p>세컨팀 멤버가 되고싶으신가요? 신청서를 작성하시면 시소가 검토후 사전 대기열에 따라 세컨팀 멤버로 등록해드립니다.</p>
          <div className="in-btn">
            <a href="https://seesorufree.notion.site/7a78b679cc284478a2282b4ab9d98c87" target="_blank">초대장 신청하기</a>
          </div>
        </div>
      </div> */}
      <div className="main-faq">
        <h2>자주 물어보는 질문</h2>
        <div className="inner">
          <ul>
            {
              faq.map(({ question, answer }, index) => (
                <li className={idx === index ? 'selected' : ''} onClick={() => setIdx(index)}>
                  <em>{question}</em>
                  <div className="ans">
                    <p>{answer}</p>
                  </div>
                </li>
              ))
            }
          </ul>
          {/* <div className="in-btn">
            <a href="#">자세히 알아보기</a>
          </div> */}
        </div>
      </div>
      <div className="steam-join">
        <div className="inner">
          <strong>충분한 실력의 인재를 발견하면<br />함께 일할 기회를 먼저 만드세요.</strong>
          {/* <p>내 전문성과 무관한 단순 부업이 아닌 내가 잘하고, 하고싶은 일을 하며 성장에 도움이 되는 일에 도전해보세요.</p>
          <p>세컨팀은 당신의 가능성을 넓혀드립니다.</p> */}
          <div className="in-btn">
            <a href="#" target="_blank">세컨팀 의뢰하기</a>
          </div>
        </div>
      </div>
    </>
  )
};

export default () => {
  const [type, setType] = useState(window.location.hash === '#partnership' ? 1 : 0); // 0 - 전문가, 1 - 회사

  useEffect(() => {
    window.location.hash = ['#partnership', '#enterprise'][type];
  }, [type]);

  useEffect(() => {
    setType(window.location.hash === '#partnership' ? 0 : 1);
  }, [window.location.hash])

  return (
    <>
      <div className="steam-def">
        <strong>세컨팀은 소수의 파트너사 중심으로 <br />협업을 진행하고 있습니다.</strong>
        <p>세컨팀은 단순 중개나 매칭만 드리는 서비스는 진행하지 않습니다.</p>
      </div>
      <div className="how-tab">
        <ul>
          <li className={type === 0 ? 'selected' : ''}><a style={{ cursor: 'pointer' }} onClick={() => setType(0)}>프로젝트 파트너십</a></li>
          <li className={type === 1 ? 'selected' : ''}><a style={{ cursor: 'pointer' }} onClick={() => setType(1)}>엔터프라이즈 파트너십</a></li>
        </ul>
      </div>

      {
        type === 0 ?
          <PartnershipView />
          :
          <EnterpriseView />
      }
    </>
  )
}