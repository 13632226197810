import { useLocation } from "react-router-dom";

const TopBanner = () => {
  const { pathname } = useLocation();
  if (pathname !== '/') {
    return <></>;
  }

  return (
    <div
      className="top-bnr"
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        window.location.href = '/#company';
      }}
    >
      <a>
        <p className="beta">
          {/* <span>N잡러들과 일해보고 싶나요?</span> */}
          세컨팀 멤버가 되어주세요.
        </p>
      </a>
    </div>
  );
}

export default TopBanner;
